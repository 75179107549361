<template>
    <div class="card">
        <div class="card-header">
            <span style="margin:0px 18px;white-space:nowrap;">{{ $route.meta.title }}</span>
            <div style="flex-grow:1"></div>
            <a-button-group >
                <slot name="button" />
            </a-button-group>
            <div style="min-width:18px;height:100%"> </div>
        </div>
        <div class="card-content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.card {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: white;
    display: block;
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    overflow: auto;
    .card-header {
        overflow: auto hidden;
        width: 100%;
        height: 56px;
        padding: 5px;
        display: flex;
        justify-content: stretch;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
    }
    .card-content {
        height: calc(100% - 56px);
        overflow: auto;
        padding: 10px;
    }
}
.card:hover {
    border-color: rgba(0, 0, 0, 0.16);
    box-shadow: 0 7px 12px rgba(0, 0, 0, 16%);
}
</style>

export default [
    { path: '/todo', name: 'Todo', meta: { title: '待办', layout: true }, component: () => import('@/views/Todo.vue') },
    // { path: '/home', name: 'Home', meta: { title: '主页', layout: true }, component: () => import('@/views/Home.vue') },
    // // { path: '/systemSetting', name: 'SystemSetting', meta: { title: '系统设置', layout: true }, component: () => import('@/views/SystemSetting.vue') },
    { path: '/supplier', name: 'Supplier', meta: { title: '供应商', layout: true }, component: () => import('@/views/Supplier.vue') },
    { path: '/roleManage', name: 'RoleManage', meta: { title: '角色管理', layout: true }, component: () => import('@/views/RoleManage.vue') },
    { path: '/permissionSetting', name: 'PermissionSetting', meta: { title: '权限设置', layout: true }, component: () => import('@/views/PermissionSetting.vue') },
    { path: '/Storage', name: 'Storage', meta: { title: '存储区管理', layout: true }, component: () => import('@/views/Storage.vue') },
    { path: '/Category', name: 'Category', meta: { title: '分类设置', layout: true }, component: () => import('@/views/Category.vue') },
    { path: '/Material', name: 'Material', meta: { title: '物料管理', layout: true }, component: () => import('@/views/Material.vue') },
    { path: '/Inventory', name: 'Inventory', meta: { title: '库存信息', layout: true }, component: () => import('@/views/Inventory.vue') },
    { path: '/InStorage', name: 'InStorage', meta: { title: '入库发起', layout: true }, component: () => import('@/views/InStorage.vue') },
    { path: '/InStorageRecord', name: 'InStorageRecord', meta: { title: '入库记录', layout: true }, component: () => import('@/views/InStorageRecord.vue') },
    { path: '/OutStorage', name: 'OutStorage', meta: { title: '出库发起', layout: true }, component: () => import('@/views/OutStorage.vue') },
    { path: '/OutStorageRecord', name: 'OutStorageRecord', meta: { title: '出库记录', layout: true }, component: () => import('@/views/OutStorageRecord.vue') },
    { path: '/Prewarning', name: 'Prewarning', meta: { title: '预警设置', layout: true }, component: () => import('@/views/Prewarning.vue') },
]
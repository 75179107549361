const apis = {
    getLoginConfig: { url: '/user/getLoginConfig', token: false, permission: [], cache: true },
    login: { url: '/user/login', token: false, permission: [], cache: false },
    checkLogin: { url: '/user/checkLogin', token: true, permission: [], cache: false },
    getUserInfo: { url: '/user/getUserInfo', token: true, permission: [], cache: true },
    getPermission: { url: '/user/getPermission', token: true, permission: [], cache: true },
    getDataLog: { url: '/dataLog/getDataLog', token: true, permission: [], cache: false },
    syncAddressBook: { url: '/system/syncAddressBook', token: true, permission: ['system.addressbook.sync'], cache: false },
    uploadImage: { url: '/system/uploadImage', token: true, permission: [], cache: false },
    addSupplier: { url: '/supplier/addSupplier', token: true, permission: ['supplier.add'], cache: false },
    saveSupplier: { url: '/supplier/saveSupplier', token: true, permission: ['supplier.edit'], cache: false },
    getSupplierCount: { url: '/supplier/getSupplierCount', token: true, permission: ['supplier.view'], cache: false },
    getSupplierList: { url: '/supplier/getSupplierList', token: true, permission: ['supplier.view'], cache: false },
    getSupplierDetail: { url: '/supplier/getSupplierDetail', token: true, permission: ['supplier.view'], cache: false },
    deleteSupplier: { url: '/supplier/deleteSupplier', token: true, permission: ['supplier.delete'], cache: false },
    getRoleList: { url: '/system/getRoleList', token: true, permission: [], cache: true },
    getUserList: { url: '/system/getUserList', token: true, permission: [], cache: true },
    addRole: { url: '/system/addRole', token: true, permission: ['system.role.add'], cache: false },
    editRole: { url: '/system/editRole', token: true, permission: ['system.role.edit'], cache: false },
    changeRoleUser: { url: '/system/changeRoleUser', token: true, permission: ['system.role.edit'], cache: false },
    getCouldSelectMemberRange: { url: '/system/getCouldSelectMemberRange', token: true, permission: [], cache: true },
    getDepartmentTree: { url: '/system/getDepartmentTree', token: true, permission: [], cache: true },
    getDepartmentList: { url: '/system/getDepartmentList', token: true, permission: [], cache: true },
    getPagePermissionList: { url: '/system/getPagePermissionList', token: true, permission: [], cache: true },
    getOperationPermissionList: { url: '/system/getOperationPermissionList', token: true, permission: [], cache: true },
    getMemberPermission: { url: '/system/getMemberPermission', token: true, permission: ['system.permission.view'], cache: false },
    saveMemberPermission: { url: '/system/saveMemberPermission', token: true, permission: ['system.permission.edit'], cache: false },
    getStorageList: { url: '/erp/getStorageList', token: true, permission: [], cache: true },
    addStorage: { url: '/erp/addStorage', token: true, permission: ['erp.storate.add'], cache: false },
    editStorage: { url: '/erp/editStorage', token: true, permission: ['erp.storage.edit'], cache: false },
    getStorageTree: { url: '/erp/getStorageTree', token: true, permission: [], cache: true },
    getCategoryList: { url: '/erp/getCategoryList', token: true, permission: [], cache: true },
    addCategory: { url: '/erp/addCategory', token: true, permission: ['erp.category.add'], cache: false },
    editCategory: { url: '/erp/editCategory', token: true, permission: ['erp.category.edit'], cache: false },
    getCategoryTree: { url: '/erp/getCategoryTree', token: true, permission: [], cache: true },
    getUsedCategoryPrefixList: { url: '/erp/getUsedCategoryPrefixList', token: true, permission: [], cache: false },
    getMaterialTagList: { url: '/material/getMaterialTagList', token: true, permission: ['material.view.basis'], cache: true },
    getMaterialUnitList: { url: '/material/getMaterialUnitList', token: true, permission: ['material.view.basis'], cache: true },
    getMaterialDetail: { url: '/material/getMaterialDetail', token: true, permission: ['material.view.basis'], cache: true },
    checkMaterialSuffix: { url: '/material/checkMaterialSuffix', token: true, permission: [], cache: false },
    addMaterial: { url: '/material/addMaterial', token: true, permission: ['material.add'], cache: false },
    editMaterial: { url: '/material/editMaterial', token: true, permission: ['material.edit'], cache: false },
    searchMaterial: { url: '/material/searchMaterial', token: true, permission: ['material.search'], cache: true },
    getMaterialBrief: { url: '/material/getMaterialBrief', token: true, permission: [], cache: true },
    getTotalSupplierList: { url: '/supplier/getTotalSupplierList', token: true, permission: [], cache: true },
    preInStorage: { url: '/material/preInStorage', token: true, permission: ['material.inventory.in_storage.apply'], cache: false },
    applyInStorage: { url: '/material/applyInStorage', token: true, permission: ['material.inventory.in_storage.apply'], cache: false },
    searchInStorageSerial: { url: '/material/searchInStorageSerial', token: true, permission: ['material.inventory.in_storage.view'], cache: false },
    searchInStorageDateRange: { url: '/material/searchInStorageDateRange', token: true, permission: ['material.inventory.in_storage.view'], cache: false },
    searchInStorageNewest: { url: '/material/searchInStorageNewest', token: true, permission: ['material.inventory.in_storage.view'], cache: false },
    searchInStoragePendingAudit: { url: '/material/searchInStoragePendingAudit', token: true, permission: ['material.inventory.in_storage.view'], cache: false },
    getInStorageDetail: { url: '/material/getInStorageDetail', token: true, permission: ['material.inventory.in_storage.view'], cache: false },
    operateInStorage: { url: '/material/operateInStorage', token: true, permission: ['material.inventory.in_storage.audit', 'material.inventory.in_storage.undo', 'material.inventory.in_storage.undo_audit'], cache: false },
    getInventoryList: { url: '/material/getInventoryList', token: true, permission: ['material.inventory.view'], cache: false },
    getMaterialInventoryDetail: { url: '/material/getMaterialInventoryDetail', token: true, permission: ['material.inventory.view'], cache: false },
    getMaterialInventoryChangeDetail: { url: '/material/getMaterialInventoryChangeDetail', token: true, permission: ['material.inventory.view'], cache: false },
    searchValidMaterial: { url: '/material/searchValidMaterial', token: true, permission: ['material.search'], cache: true },
    getMaterialInventoryList: { url: '/material/getMaterialInventoryList', token: true, permission: ['material.search'], cache: false },
    applyOutStorage: { url: '/material/applyOutStorage', token: true, permission: ['material.inventory.out_storage.apply'], cache: false },
    searchOutStorageSerial: { url: '/material/searchOutStorageSerial', token: true, permission: ['material.inventory.out_storage.view'], cache: false },
    searchOutStorageDateRange: { url: '/material/searchOutStorageDateRange', token: true, permission: ['material.inventory.out_storage.view'], cache: false },
    searchOutStorageNewest: { url: '/material/searchOutStorageNewest', token: true, permission: ['material.inventory.out_storage.view'], cache: false },
    searchOutStoragePendingAudit: { url: '/material/searchOutStoragePendingAudit', token: true, permission: ['material.inventory.out_storage.view'], cache: false },
    getOutStorageDetail: { url: '/material/getOutStorageDetail', token: true, permission: ['material.inventory.out_storage.view'], cache: false },
    operateOutStorage: { url: '/material/operateOutStorage', token: true, permission: ['material.inventory.out_storage.audit', 'material.inventory.out_storage.undo', 'material.inventory.out_storage.undo_audit'], cache: false },
    addPrewarning: { url: '/material/addPrewarning', token: true, permission: ['material.inventory.prewarning.add'], cache: false },
    getPrewarningList: { url: '/material/getPrewarningList', token: true, permission: ['material.inventory.prewarning.view'], cache: true },
    deletePrewarning: { url: '/material/deletePrewarning', token: true, permission: ['material.inventory.prewarning.delete'], cache: false },
}

export default apis
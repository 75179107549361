import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"

export default createStore({
    state: {
        backend_url: 'http://api.erp.quarkmed.cn',
        local_setting: {
            auto_login: false,
            cache_mode: true
        },
        token: '',
        user_info: { user_id: '', avatar: '', gender: '', name: '' },
        page_list: [],
        operation_list: [],
        api_cache: {},

    },
    mutations: {
        login(state, payload) {
            state.token = payload.token
        },
        logout(state, payload) {
            state.token = ''
            state.user_info = {}
            state.page_list = []
            state.operation_list = []
        },
        local_setting(state, payload) {
            for (let item in payload) {
                state.local_setting[item] = payload[item]
            }
        },
        user_info(state, payload) {
            state.user_info = payload
        },
        permission(state, payload) {
            let t1 = []
            payload.page_list.forEach(i => {
                t1.push(i.toLowerCase())
            });
            state.page_list = t1
            let t2 = []
            payload.operation_list.forEach(i => {
                t2.push(i.toLowerCase())
            });
            state.operation_list = t2
        },
        api_cache(state, payload) {
            if (state.api_cache[payload.api] == undefined) {
                state.api_cache[payload.api] = {}
            }
            let name = JSON.stringify(payload.data)
            state.api_cache[payload.api][name] = JSON.stringify(payload.res)
        }
    },
    getters: {
        apiCache: state => (api, data) => {
            const cache = state.api_cache[api]
            if (cache !== undefined) {
                let name = JSON.stringify(data)
                if (cache[name] !== undefined) {
                    return JSON.parse(cache[name])
                }
            }
            return null
        },
        operationPermission: state => (parent) => {
            const permissions = state.operation_list
            parent = parent.split('.')
            let res = []
            permissions.forEach(item => {
                item = item.split('.')
                if (item.length > parent.length) {
                    let [flag, i] = [true, 0]
                    for (; i < parent.length; i++) {
                        if (parent[i] != item[i]) flag = false
                    }
                    if (flag) res.push(item.splice(i, item.length - i).join('.'))
                }
            })
            return res
        },
        checkPermission: state => (operation_permission) => {
            return state.operation_list.indexOf(operation_permission) != -1
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [
        createPersistedState({
            reducer(state) {
                return {
                    local_setting: state.local_setting,
                    token: state.token,
                    user_info: state.user_info,
                    page_list: state.page_list,
                    operation_list: state.operation_list
                }
            }
        })
    ]
})

import axios from 'axios';
// import md5 from 'md5';
// import { Notification, MessageBox } from 'element-ui';

/**
 * 发送phalapi请求
 * @param {String} url API请求地址
 * @param {Object} postdata 传递的数据
 * @return {Any} result 结果
 */
function phalapi(url, postdata = {}) {
    let data = new FormData();
    for (let key in postdata) {
        data.append(key, postdata[key]);
    }
    return new Promise((resolve, reject) => {
        axios({
            url,
            data,
            method: 'POST'
        }).then(res => {
            if (res.status == 200) {
                resolve(res.data)
            } else {
                reject(`[${res.status}] ${res.statusText}`)
            }
        }).catch(err => {
            reject(`[999] ${err}`);
        });
    });
}
export default phalapi;

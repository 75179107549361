<template>
    <a-layout class="main-layout">
        <a-layout-header class="header" style="background: #e5f2ff;">
            <img src="@/assets/logo4.png" alt="" style="height:48px" />
            <div>
                <a-dropdown :trigger="['click']">
                    <a class="ant-dropdown-link"> {{ user_info.name }} <DownOutlined /> </a>
                    <template #overlay>
                        <a-menu>
                            <a-menu-item key="0">
                                <div @click="$router.push('/myuserinfo')">个人信息</div>
                            </a-menu-item>
                            <a-menu-divider />
                            <a-menu-item key="0">
                                <div @click="$router.push('/login')">重新登录</div>
                            </a-menu-item>
                            <a-menu-item key="1">
                                <div @click="$router.push('/logout')">退出登录</div>
                            </a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
                <a-avatar :src="user_info.avatar" size="large" style="margin-left:15px" />
            </div>
        </a-layout-header>
        <a-layout>
            <a-layout-sider :class="{ flod }" v-model:collapsed="collapsed" collapsible :collapsedWidth="flod ? 0 : 80">
                <Menu />
            </a-layout-sider>
            <a-layout>
                <a-layout-content class="content" v-if="permission">
                    <slot />
                </a-layout-content>
                <a-layout-content class="content" v-else>
                    <!-- 无权访问 -->
                </a-layout-content>
                <a-layout-footer class="footer" v-if="flod">QuarkMed ERP System</a-layout-footer>
                <a-layout-footer class="footer" v-else>QuarkMed ERP System v0.1.0Beta✒ 2021🎉 Powered by V5.Xie🎁</a-layout-footer>
            </a-layout>
        </a-layout>
    </a-layout>
</template>

<script>
import { DownOutlined } from '@ant-design/icons-vue';
import Menu from './Menu';
export default {
    components: {
        Menu,
        DownOutlined,
    },
    watch: {
        // 每次路由改变时检查权限
        $route(value) {
            this.permission = false;
            this.checkPermission(value.name);
        },
    },
    computed: {
        user_info() {
            return this.$store.state.user_info;
        },
        page_list() {
            return this.$store.state.page_list;
        },
    },
    data() {
        return {
            flod: document.body.clientWidth >= 640 ? false : true,
            collapsed: false,
            permission: false,
        };
    },
    methods: {
        checkPermission(name) {
            if (!this.page_list) {
                setTimeout(() => {
                    this.checkPermission(name);
                }, 100);
            } else if (this.$route.meta.layout && this.page_list.indexOf(name.toLowerCase()) == -1) {
                this.permission = false;
                this.$router.go(-1);
                this.$modal.error({
                    title: '无权限',
                    content: `无权限访问该页面[${name}]`,
                });
            } else {
                this.permission = true;
            }
        },
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.flod = document.body.clientWidth >= 640 ? false : true;
            })();
        };
        this.checkPermission(this.$route.name);
    },
};
</script>

<style lang="less" scoped>
.main-layout {
    width: 100%;
    height: 100vh;
}
.content {
    padding: 10px 10px 5px 10px;
    .card {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: white;
        display: block;
        transition: all 0.3s;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        .card-header {
            height: 56px;
            padding: 5px 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f0f0f0;
        }
    }
    .card:hover {
        border-color: rgba(0, 0, 0, 0.16);
        box-shadow: 0 7px 12px rgba(0, 0, 0, 16%);
    }
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}
.footer {
    text-align: center;
    padding: 0 0 5px 0;
}
.flod {
    position: absolute;
    height: calc(100vh - 64px);
    z-index: 999;
}
</style>

<template>
    <a-menu theme="dark" v-model:selectedKeys="selected" mode="inline" @click="clickMenu">
        <a-menu-item key="Todo" v-if="show('Todo')">
            <PieChartOutlined />
            <span>待办</span>
        </a-menu-item>
        <a-menu-item key="Home" v-if="show('Home')">
            <PieChartOutlined />
            <span>主页</span>
        </a-menu-item>
        <a-sub-menu>
            <template #title>
                <BorderOuterOutlined />
                <span>库存管理</span>
            </template>
            <a-menu-item key="Inventory" v-if="show('Inventory')">
                <TableOutlined />
                <span>库存信息</span>
            </a-menu-item>
            <a-menu-item key="InStorage" v-if="show('InStorage')">
                <ImportOutlined />
                <span>入库发起</span>
            </a-menu-item>
            <a-menu-item key="InStorageRecord" v-if="show('InStorageRecord')">
                <MergeCellsOutlined />
                <span>入库记录</span>
            </a-menu-item>
            <a-menu-item key="OutStorage" v-if="show('OutStorage')">
                <ExportOutlined />
                <span>出库发起</span>
            </a-menu-item>
            <a-menu-item key="OutStorageRecord" v-if="show('OutStorageRecord')">
                <SplitCellsOutlined />
                <span>出库记录</span>
            </a-menu-item>
            <a-menu-item key="Prewarning" v-if="show('Prewarning')">
                <BellOutlined />
                <span>预警管理</span>
            </a-menu-item>
        </a-sub-menu>

        <a-sub-menu>
            <template #title>
                <SettingOutlined />
                <span>设置</span>
            </template>
            <a-menu-item-group key="systemSetting">
                <template #title>
                    <span>系统</span>
                </template>
                <a-menu-item key="RoleManage" v-if="show('RoleManage')"><TeamOutlined />角色管理</a-menu-item>
                <a-menu-item key="PermissionSetting" v-if="show('PermissionSetting')"><CodeSandboxOutlined />权限设置</a-menu-item>
                <!-- <a-menu-item key="SystemSetting" v-if="show('SystemSetting')"><PieChartOutlined />系统设置</a-menu-item> -->
            </a-menu-item-group>
            <a-menu-item-group key="erpSetting">
                <template #title>
                    <span>ERP</span>
                </template>
                <a-menu-item key="Storage" v-if="show('Storage')"><ContainerOutlined />储存区管理</a-menu-item>
                <a-menu-item key="Category" v-if="show('Category')"><BorderInnerOutlined />分类管理</a-menu-item>
                <a-menu-item key="Supplier" v-if="show('Supplier')"><PieChartOutlined /><span>供应商</span></a-menu-item>
                <a-menu-item key="Material" v-if="show('Material')"><BuildOutlined /><span>物料管理</span></a-menu-item>
            </a-menu-item-group>
        </a-sub-menu>
    </a-menu>
</template>

<script>
import { MenuFoldOutlined, MenuUnfoldOutlined, ExportOutlined, BellOutlined, SplitCellsOutlined, TableOutlined, ImportOutlined, BorderOuterOutlined, BuildOutlined, PieChartOutlined, MergeCellsOutlined, MailOutlined, BorderInnerOutlined, DesktopOutlined, InboxOutlined, AppstoreOutlined, SettingOutlined, TeamOutlined, CodeSandboxOutlined, ContainerOutlined } from '@ant-design/icons-vue';
import routers from '../router/routers';
export default {
    components: {
        MenuFoldOutlined,
        BellOutlined,
        MergeCellsOutlined,
        MenuUnfoldOutlined,
        PieChartOutlined,
        MailOutlined,
        TableOutlined,
        ExportOutlined,
        ImportOutlined,
        BorderOuterOutlined,
        DesktopOutlined,
        InboxOutlined,
        SplitCellsOutlined,
        AppstoreOutlined,
        SettingOutlined,
        TeamOutlined,
        CodeSandboxOutlined,
        ContainerOutlined,
        BorderInnerOutlined,
        BuildOutlined,
    },
    data() {
        return {
            loading: true,
            selected: [this.$route.name],
        };
    },
    computed: {
        page_list() {
            return this.$store.state.page_list;
        },
    },
    watch: {
        $route(value) {
            this.selected[0] = value.name;
        },
    },
    created() {
        // this.$api('getPagePermission').then((res) => {
        //     this.show_page_list = res.page_list;
        // });
    },
    methods: {
        show(page) {
            // console.log(this.$store.state.page_list)
            return this.page_list.indexOf(page.toLowerCase()) != -1;
        },
        clickMenu(event) {
            this.$router.push(event.key.toLowerCase());
        },
    },
};
</script>

<style lang="less" scoped></style>

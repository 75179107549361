import { createRouter, createWebHistory } from 'vue-router'
import routers from './routers'
const routes = [
    {
        path: '/',
        name: '',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'Index',
        meta: {
            layout: false,
            title: 'ERP'
        },
        component: () => import('@/views/Index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            layout: false,
            title: '登录'
        },
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: {
            layout: false,
            title: '登出'
        },
        component: () => import('@/views/Logout.vue')
    },
]
routers.forEach(item => {
    routes.push(item)
})

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

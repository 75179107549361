<template>
    <div class="app">
        <a-config-provider :locale="locale">
            <div v-if="alive">
                <base-layout v-if="this.$route.meta.layout">
                    <router-view />
                </base-layout>
                <router-view v-else />
            </div>
        </a-config-provider>
    </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
    data() {
        return { alive: true, locale: zhCN };
    },
    provide() {
        return { reload: this.reload };
    },
    methods: {
        reload() {
            this.alive = false;
            this.$nextTick(() => {
                this.alive = true;
            });
        },
    },
};
</script>

<style lang="less" scoped></style>

import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
import router from './router'
import store from './store'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import { message, Modal } from 'ant-design-vue';
import api from './apis'

import pinyin from 'js-pinyin'


app.config.globalProperties.$api = api
app.config.globalProperties.$message = message
app.config.globalProperties.$modal = Modal
app.config.globalProperties.$pinyin = pinyin

import BaseLayout from './components/BaseLayout.vue'
app.component('base-layout', BaseLayout)
import BaseContent from './components/BaseContent.vue'
app.component('base-content', BaseContent)

app.use(store).use(router).use(Antd).mount('#app')


import phalapi from './phalapi'
import apis from './apis'
import store from '../store'
import { notification } from 'ant-design-vue'
import router from '../router'

function url(api) {
    let backend_url = store.state.backend_url
    return backend_url + api.url;
}

function api(api, data = {}, disable_cache = false) {
    return new Promise((resolve, reject) => {
        if (apis[api] === undefined) {
            console.error(`未定义的接口：${api}`)
            reject()
        } else {
            let api_ = apis[api];
            api_.name = api
            api = api_
        }
        if (api.token) {
            if (store.state.token && store.state.token.length == 24) {
                data.token = store.state.token
            } else {
                notification.error({
                    message: `未登录`,
                    description: `可能是登录过期或登录无效，请重新登录`
                })
                setTimeout(router.replace, 500, '/logout')
            }
        }
        if (store.state.local_setting.cache_mode && !disable_cache && api.cache) {
            let res = store.getters.apiCache(api.name, data)
            if (res !== null) {
                resolve(res)
                return
            }
        }
        phalapi(url(api), data).then(res => {
            switch (res.ret) {
                case 200:
                    resolve(res.data)
                    if (api.cache) {
                        store.commit('api_cache', { api: api.name, data, res: res.data })
                    }
                    break;
                case 312:
                    notification.error({
                        message: `无权限`,
                        description: `${res.msg}`
                    })
                    break;
                case 321:
                    notification.error({
                        message: `未登录`,
                        description: `可能是登录过期或登录无效，请重新登录`
                    })
                    setTimeout(router.replace, 500, '/logout')
                    break;
                case 404:
                    notification.error({
                        message: `访问了不存在的接口`,
                        description: `API路径：${api.url}`
                    })
                    break;
                case 500:
                    notification.error({
                        message: `后端服务器运行错误`,
                        description: `错误说明：${res.msg}`
                    })
                    break;
                default:
                    notification.error({
                        message: 'API请求失败',
                        description: res.msg
                    })
                    break;
            }
        }).catch(err => {
            notification.error({
                message: 'API请求失败',
                description: err
            })
        })
    })
}
export default api